<template>
  <div id="app">
      <div class="r-suspension">
          <template v-for="(item,index) in suspensionList">
              <div class="itembox" @click="itemClick(item)" 
              :class="{ 'actives': actives == index}" 
              @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)">
                  <div class="item flex ai-c cp">
                      <el-image style="width: 0.32rem;height: 0.32rem;" class="left-img"  :src="require('@/assets/images/' + item.url + '.png')" fit="cover"></el-image>
                      <span class="ta-c">{{ item.text }}</span>
                  </div>
              </div>
          </template>
      </div>
      <RouterView></RouterView>

      <el-dialog class="leaveformbox" title="留言" :visible.sync="leaveMessagedialog" width="45%">
          <el-form :model="leaveform" ref="leaveform" label-width="0.6rem">

            <el-form-item label="标题" prop="title" :rules="$rules.NAME20">
              <el-input v-model="leaveform.title" autocomplete="off" placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item label="姓名" prop="userName" >
                        <el-input v-model="leaveform.userName" autocomplete="off" placeholder="请输入您的姓名"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
                    <el-form-item label="电话" prop="tel" :rules="$rules.PHONE">
                          <el-input  v-model="leaveform.tel" autocomplete="off" placeholder="请输入您的电话"></el-input>
                      </el-form-item>
                </el-col>  
            </el-row>
            <el-form-item label="留言" prop="content" :rules="$rules.NAME300">
              <el-input  :autosize="{ minRows: 3, maxRows: 6 }" type="textarea" v-model="leaveform.content" autocomplete="off" placeholder="请输入您的留言"></el-input>
            </el-form-item>
            <el-form-item class="ta-c mt-30">
              <el-button style="width:2rem ;margin-left: -0.6rem;background-color: #67C23A;border-color: #67C23A;" type="primary" @click="submitForm()">提交</el-button>
            </el-form-item>
          </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { RouterView } from 'vue-router';


export default {
  name: 'app',
  data() {
    return {
      actives: null,
      isitemEnter:false,
      suspensionList: [
        {
          url: 'dianhua-icon',
          text:'15701647232'
        },
        {
          url: 'liuyan-icon',
          text: '留言'
        },
        {
          url: 'dingbu-icon2',
          text: '返回顶部'
        },
      ],
      leaveMessagedialog: false,
      leaveform: {
        title: null,//标题
        tel: null,//电话
        userName: null,//姓名
        content:null,//内容
      }
    }
  },
  components: {
    RouterView
  },
  mounted() {
    this.getTypeList()
  },
  methods: {
    getTypeList(refresh) {

      let params = {
        current: 0,
        size: 99,
      }

      this.$http.get('/user/type/page', { params }).then(res => {
        if (res.code == 200) {
          let { records } = res.data
          let typeList = JSON.parse(JSON.stringify(records))
          this.$store.commit('UPDATE_TEMTYPT', typeList)

        } else {
          // this.$message.error(res.message)
        }
      })
    },
    submitForm(formName) {
      this.$refs['leaveform'].validate((valid) => {
        if (valid) {

          let params = JSON.parse(JSON.stringify(this.leaveform))
          this.$http.post('/user/word/add', params).then(res => {
            if (res.code == 200) {
                  this.$message.success('我们已成功收到您的留言，感谢您的反馈')
                  this.leaveMessagedialog = false
            } else {
              this.$message.error(res.message)
            }
          }).catch(err => {
            
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    itemClick(item) {
      if (item.text == '返回顶部') {
        //  设置一个定时器
        const upRoll = setInterval(() => {
          const top = document.documentElement.scrollTop // 每次获取页面被卷去的部分
          const speed = Math.ceil(top / 8) // 每次滚动多少 （步长值）
          if (document.documentElement.scrollTop > 0) {
            document.documentElement.scrollTop -= speed // 不在顶部 每次滚动到的位置
          } else {
            clearInterval(upRoll) // 回到顶部清除定时器
          }
        }, 15)
      } else if (item.text == '留言') {
        this.leaveMessagedialog = true
         
        this.leaveform.title = null
        this.leaveform.tel = null
        this.leaveform.userName = null
        this.leaveform.content = null
      }
    },
    mouseEnter(index) {
      this.actives = index
      this.isitemEnter = true
    },
    mouseLeave(index) {
      this.actives = null
      this.isitemEnter = false
    }


  },



}
</script>

<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
  color: #333333;

  font-size: 0.16rem;
}
.r-suspension{
  height: auto;
  z-index: 99999999999;
  position: fixed;
  right: 0;
  top: 47%;
  z-index: 10;
  transform: translate(0,-47%);
  .itembox{
    width: 0.5rem;
    height: 0.5rem;
    margin-bottom: 0.2rem;
    position: relative;
    
    .item{
      position: absolute;
      padding: 0.1rem 0.1rem;
      box-sizing: border-box;
      width: auto;
      border-radius: 0.05rem;
      color: #ffffff;
      font-size: 0.14rem;
      background-color: #248EFC;
      
      // right: -1.27rem;
      
      
      
rgb(16, 31, 46)      
      .left-img{
        margin: 0 0.02rem;
      }
      span{
        margin-left: 0.1rem;
        white-space:nowrap;
        
      }
    }
  }
  
  .actives{  
    .item{
      // margin-right: 0;
      animation:show 0.5s 1;
      animation-fill-mode:forwards;
      background-color: #ff0000;
      border-radius: 0.05rem;
    }
    
  }
  // .actives.itemLeave{
  //   .item{
  //     margin-right: -1.27rem;
  //     // animation:hide 0.5s 1;
  //     // animation-fill-mode:forwards;
  //     // background-color: #248EFC;
  //     // border-radius: 0.05rem;
  //   }
    
  // }
  @keyframes show
  {
    0%   {right: -1.27rem;}
    
    100% {right: 0rem;}
  }
  @keyframes hide
  {
    0%   {right: 0rem;}
    
    100% {right: -1.27rem;}
  }
}
</style>
