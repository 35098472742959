import Vue from 'vue'
import Vuex from 'vuex'
// 引入vuex持久化方法createPersistedState
import createPersistedState from 'vuex-persistedstate'

import mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    temType: null, // 类型
  },
  getters,
  mutations,
  actions,
  plugins: [
    createPersistedState({
      key: "JinShiGuangLian-QD",
    }),
  ],
})
