import config from './config'
import Axios from 'axios'
import router from '../router'
import store from "@/store";

// 引入提示框
import { MessageBox } from 'element-ui'
import { Message } from 'element-ui'

const axios = Axios.create({
    baseURL: config.baseUrl,
    timeout: 150000, // request timeout  
    headers: {
        // "Content-Type": "application/json",
        // 'Content-Type': 'multipart/form-data' 
    }
});


// request前置拦截
// 每次请求都附带上我们header的Authorization
axios.interceptors.request.use(
    config => {
       
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);



// 添加响应拦截器

axios.interceptors.response.use(

    response => {
        // 2xx 范围内的状态码都会触发该函数。
        return response.data;
    },


    error => {
        // 超出 2xx 范围的状态码都会触发该函数。

        console.log(error)
        // if (error.code == 'ERR_NETWORK') {
        //     router.push("/blank")
        // }
        //其他的异常
        // if (error.response.data) {
        //     error.message = error.response.data.message
        // }
        // 没有权限
        // if (error.response.status === 401) {
        //     router.push("/login")
        // }
        // Message.error(error.response.data.message, { duration: 3000 })
        return Promise.reject(error)
    }
)

export default axios