<template>
  <div class="home">
      <!--轮播图-->
      <!-- <carousel></carousel> -->
      <!--服务领域-->
      <div class="operateArea common-content">
          <el-row class="common-title">
              <div class=" fs-40 fw-b">服务领域</div>
              <el-divider class="divider fs-16 ">SERVICE AREA</el-divider>
          </el-row>

          <el-row class="operateArea-list flex jc-sb flex-wp mt-30 w">
              <template v-for="item in operateAreaList">
                  <el-col class="item cp" :xs="12" :sm="4" :md="4" :lg="4" :xl="4" v-on:mouseenter.native="mouseEnter(item)" @mouseleave.native="mouseLeave(item)">
                      <el-image  :src="require('../assets/images/' + item.url + '.png')" fit="cover"></el-image>
                      <div class="fs-18 fw-b c666">{{ item.title }}</div>
                      <p class="fs-14">{{ item.p }}</p>
                </el-col>
              </template>
          </el-row>
      </div>
      <!--案例介绍-->
      <div class="caseIntro common-content">
          <el-row class="common-title">
              <div class=" fs-40 fw-b">案例介绍</div>
              <el-divider class="divider fs-16 ">CASE STUDY</el-divider>
          </el-row>

          <el-row class="caseIntro-list flex  flex-wp mt-30 w">
              <template v-for="item in caseList">
                  <el-col class="item cp" :xs="11" :sm="5" :md="5" :lg="5" :xl="5">
                      <el-image class="anliImg" style="height: 100%;width: 100%;"  :src="require('../assets/images/' + item.url + '.png')" fit="cover"></el-image>
                      <div class="item-content flex jc-c ai-c">
                          <div>
                              <div class="fs-18 fw-b">{{ item.title }}</div>
                              <p class="fs-14">{{ item.type }}</p>
                              <el-image class="caseImg"  :src="require('../assets/images/case.png')" fit="cover"></el-image>
                          </div>
                      </div>
                  </el-col>
              </template>  
          </el-row>
          <el-button class="mt-30" type="success" style="width:2rem;line-height:0.2rem" @click="toPath('/case')">查看更多</el-button>
      </div>
      <!--新闻资讯-->
      <div class="newsInfor common-content">
          <el-row class="common-title">
              <div class=" fs-40 fw-b">新闻资讯</div>
              <el-divider class="divider fs-16 hidden-xs-only" >NEWS AND INFORMATION</el-divider>
              <el-divider class="divider fs-16 hidden-md-only hidden-lg-only hidden-sm-only">NEWS</el-divider>
          </el-row>

          <el-row class="newsInfor-list flex  flex-wp mt-30 w">  
              <template v-for="item in newsList">
                  <el-col class="item cp" :xs="11" :sm="5" :md="5" :lg="5" :xl="5" @click.native="toNewsDetails(item)">
                      <div style="height: 1.85rem;">
                        <el-image style="height: 100%;width: 100%;"  :src="item.coverUrl? item.coverUrl:''" fit="cover"></el-image>
                      </div>
                      <div class="ta-l text-1-h pl-10" style="margin-top: 0.1rem;">{{ item.title }}</div>
                      <div class="ta-l pl-10 fs-14 c666">{{ item.createTime | dateFormat('day') }}</div>
                  </el-col>
              </template>  
          </el-row>
          <el-button class="mt-30" type="success" style="width:2rem;line-height:0.2rem" @click="toPath('/news')">查看更多</el-button>
      </div>
      <!--合作伙伴-->
      <div class="partners common-content">
          <el-row class="common-title">
              <div class=" fs-40 fw-b">合作伙伴</div>
              <el-divider class="divider fs-16 ">PARTNERS</el-divider>
          </el-row>
          <el-row class="partners-list flex  flex-wp mt-30">
              <template v-for="(item,index) in 9">
                  <el-col class="item cp" :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
                      <el-image style="width: 40%;"  :src="require('../assets/images/hezuo' + (index+1) + '.png')" fit="cover"></el-image>
                  </el-col>
              </template>  
          </el-row>
      </div>
  </div>
</template>

<script>
import carousel from "@/components/carousel.vue"
export default {
  name: 'Home',
  components: {
    carousel
  },
  data() {
    return {
        screenWidth: null,
        bannerHeight: null,
        operateAreaList: [
          {
              url: "ly1",
              title: '版权维权',
              p:'选择正规的合法途径，以保障自身权益，避免造成严重影响'
          },
          {
              url: "ly2",
              title: '版权合作',
              p:'得到版权所有者的合法授权,尊重公共利益以及他人权利,促进社会文化发展'
          },
          {
              url: "ly3",
              title: '版权收购',
              p:'大量采购电视剧、电影的版权'
          },
          {
              url: "ly4",
              title: '投资拍剧',
              p:'投资拍摄电影、电视剧、网络大电影、动画片等'
          },
          {
              url: "ly5",
              title: '贴片广告',
              p: '电视频道贴片广告'
          }

        ],
        caseList: [
          {
            url: 'anli1',
            title: '北京风尚卓尔文化传媒',
            type:'版权购买'
          },
          {
            url: 'anli2',
            title: '吉林省影视剧制作集团',
            type:'版权购买'
          },
          {
            url: 'anli3',
            title: '山东第六制片社',
            type: '版权购买'
          },
          {
            url: 'anli4',
            title: '北京原始物语文化',
            type: '版权购买'
          },
            {
            url: 'anli5',
            title: '大连五洲影视有限公司',
            type: '版权维权'
          },
          {
            url: 'anli6',
            title: '河南电影电视制作集团',
            type: '版权维权'
          },
          {
            url: 'anli7',
            title: '北京华晟泰通传媒',
            type: '版权维权'
          },
          {
            url: 'anli8',
            title: '中国广播电视协会',
            type: '版权维权'
          },
        ],
        newsList: [
          {
            url: 'new1',
            title: '探索国潮热浪中的跨界创新 · 白玉兰奖综述',
            year:'2023',
            month:'06-15'
          },
          {
            url: 'new2',
            title: '电影《封神第一部》定档，黄渤演姜子牙',
            year: '2023',
            month: '06-14'
          },
          {
            url: 'new3',
            title: '动画世界中的神秘与浪漫 · 白玉兰奖综述',
            year: '2023',
            month: '06-08'
          },
          {
            url: 'new4',
            title: '中国确定2023年知识产权七项重点工作',
            year: '2023',
            month: '01-09'
          },
          {
            url: 'new5',
            title: '热播影视剧中网络文学改编约占21%',
            year: '2021',
            month: '02-01'
          },
          {
            url: 'new6',
            title: '微短剧兴起，为影视剧开辟新“赛道”',
            year: '2021',
            month: '01-26'
          }
        ],
        typeList:[]
    }
      
  },
  computed: {
    getTemType() {
      return this.$store.getters.getTemType
    }
  },
  mounted() {

    this.getNewsList()
    
  },
  methods: {
    toPath(url) {
      this.$router.push({
        path:url
      })
    },
    toNewsDetails(item) {
      console.log(item)
     
      this.$router.push({
        path: '/newsDetails',
        query: {
          id: item.id
        }
      });
    },
    getNewsList() {
      if (!this.$initType(this.getTemType, '新闻资讯')) {
        return
      }
      
      let params = {
        current: 0,
        size: 8,
        typeId: this.$initType(this.getTemType, '新闻资讯')
      }
      // params.typeId  根据类型

      this.$http.get('/user/template/page', { params }).then(res => {
        if (res.code == 200) {
          let { records } = res.data
          this.newsList = records
        } else {
          this.$message.error(res.message)
        }
      })
    },
    mouseEnter(item) {
      let newUrl = item.url + '_'
      this.$set(item,'url', newUrl)
    },
    mouseLeave(item) {
      let newUrl = item.url.slice(0, item.url.length-1)
      this.$set(item, 'url', newUrl)
    }
  }
}
</script>

<style scoped lang="less">
.home{

  
  .common-content{
      padding: 1rem 0;
  }
  /* 服务领域 */
  .operateArea{
      background-color:#eeeeee;
      
       /deep/ .el-divider__text{
          background-color:#eeeeee;
      }
      .operateArea-list{
          .item:hover{
            .el-image{
              background-color: #529535;
              box-sizing: border-box;
              border: 0.03rem solid #529535;
            }
          }
          .el-image{
            border-radius: 50%;
            border: 0.03rem solid #fff;
            box-sizing: border-box;
            margin-bottom: 10px;
            background: transparent;
            padding: 0.2rem;
            width: 0.96rem;
            height: 0.96rem;
          }
          
          p{
            margin-top: 0.05rem;
            padding: 0 0.08rem;
          }
      }
  }

  /* 案例介绍 */
  .caseIntro{
    .caseIntro-list{
      .item{
        margin: 0 1.5vw;
        margin-bottom: 0.3rem;
        position: relative;
        overflow: hidden;
        .el-image{
          transition: all 0.3s;
        }
        .caseImg{
          width: 0.6rem;
        }
        .item-content{
          color: #ffffff;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.5);
          p{
            margin-top: 0.1rem;
          }
        }
      }
      .item:hover{
        .anliImg{
          transform: scale(1.2);
        }
        
      }
    }
  }
  /* 新闻资讯 */
  .newsInfor{
      background-color:#eeeeee;
      /deep/ .el-divider__text{
          background-color:#eeeeee;
      }
      .item{
        margin: 0 1.5vw;
        margin-bottom: 0.3rem;
        position: relative;
        overflow: hidden;
        background-color: #ffffff;
        padding-bottom: 0.15rem;
      }
      .item:hover{
 
        color: #529535;
      }
  }
  /* 合作伙伴 */
  .partners{
    .partners-list{
      width: 65%;
      margin: 0 auto;
      margin-top: 0.3rem;
      .item{
        background-color: #f5f5f5;
        border: 1px solid #e2e2e2;
        
      }
      .item:hover{
        .el-image{
          animation:doudong 0.5s 1;
        }
      }
    }
  }
  @keyframes doudong
  {
    0%   {transform: rotateY(0deg)}
    50%  {transform: rotateY(180deg)}
    100% {transform: rotateY(0deg)}
  }
 
}
</style>