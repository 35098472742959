<template>
    <el-carousel class="banner"  :height="bannerHeight + 'px'">
         <el-carousel-item class="banner-item">
             <div class="item-content">
                <el-image style="width:100%; height: inherit;margin-right: 12px;" :src="require('../assets/images/banner.png')" fit="cover"></el-image>
                <div class="banner-text flex jc-c ai-c">
                    <div>
                        <div class="title  fs-28 fw-b mb-30 c999">北京金视广联文化传媒有限公司</div>
                        <div class="text fs-18" style="padding: 0 0.1rem;">公司创立于2004年，致力于影视版权维权、版权购买、版权收购、投资拍摄电视剧、电影、贴片广告。</div>
                    </div>
               </div>
             </div>
         </el-carousel-item>
         
    </el-carousel>
</template>

<script>
export default {
  name: 'carousel',
  data() {
    return {
      screenWidth: null,
      bannerHeight: null,
    }
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth
    this.bannerHeight = 600 / 1550 * this.screenWidth
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.bannerHeight = 600 / 1550 * this.screenWidth
    }
  },
  methods: {
    
  }
}
</script>


<style scoped lang="less">
.banner{
    
    width: auto;
    .banner-item{
      .item-content{
        width: 100%;
        height: inherit;
        position: relative;
        .banner-text{
          position: absolute;
          width: 100%;
          color: #333333;
          height: inherit;
          top:0;
          left: 0;
          .title{
            margin-bottom: calc(100vw * 30 / 1600);
          }
        }
      }
      
      
      
    }
  }
</style>
