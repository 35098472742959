let baseUrl

if (process.env.NODE_ENV == "development") {
    // 开发环境中
    baseUrl = 'http://192.168.0.116:8900'
    // baseUrl = 'http://192.168.0.114:8765'
    // baseUrl = 'https://jsglcm.com/jsgl_api'
} else {
    // 生产环境中
    baseUrl = 'https://jsglcm.com/jsgl_api'
}

export default {
    baseUrl
}
