import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'



function resizeFont() {
  //获取屏幕宽度
  let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
  //设计图参考宽度
  let design = 750;
  if (clientWidth > 750)
    document.getElementsByTagName('html').item(0).style.fontSize = '100px';
  else
    document.getElementsByTagName('html').item(0).style.fontSize = (clientWidth / design)*100 + 'px';
}
resizeFont();
window.onresize = function () {
  resizeFont();
}

Vue.config.productionTip = false

import "@/assets/css/common.css"
import "@/assets/css/media.less"

import 'element-ui/lib/theme-chalk/display.css';

/* AXIOS */
import http from "./request/http.js";
Vue.prototype.$http = http;

/* 表单验证 */
import rules from '@/ulils/rules'
Vue.prototype.$rules = rules 

import '@wangeditor/editor/dist/css/style.css'

import { initType } from '@/ulils/index'
Vue.prototype.$initType = initType

Vue.filter('dateFormat', function (originVal, type) {

  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  let text = `${y}-${m}-${d} ${hh}:${mm}`
  if (type == 'day') {
    text = `${y}-${m}-${d}`
  }
  return text
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
