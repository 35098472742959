import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/layout'
  },
  {
    path: '/layout',
    name: 'Layout',
    redirect: '/home',
    component: () => import('@/views/layout/Layout.vue'),
    children: [
      {
        path: '/home',
        component: () => import('@/views/Home.vue'),
        name: 'Home',
        meta: { title: '首页' }
      },
      {
        path: '/about',
        component: () => import('@/views/About.vue'),
        name: 'About',
        meta: {title: '关于我们'}
      },
      {
        path: '/serviceArea',
        component: () => import('@/views/ServiceArea.vue'),
        name: 'ServiceArea',
        meta: { title: '服务领域' }
      },
      {
        path: '/news',
        component: () => import('@/views/News.vue'),
        name: 'News',
        meta: { title: '新闻资讯' },
      },
      {
        path: '/newsDetails',
        component: () => import('@/views/NewsDetails.vue'),
        name: 'NewsDetails',
        meta: { title: '新闻详情', parentTitle: '新闻资讯', parentPath:'/news', second:true }
      },
      {
        path: '/case',
        component: () => import('@/views/Case.vue'),
        name: 'Case',
        meta: { title: '案例介绍' }
      },
      {
        path: '/contact',
        component: () => import('@/views/Contact.vue'),
        name: 'Contact',
        meta: { title: '联系我们' }
      },
      {
        path: '/recruit',
        component: () => import('@/views/Recruit.vue'),
        name: 'Recruit',
        meta: { title: '人才招聘' }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
